.ButtonContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(118px * var(--hRatio));
  align-items: center;
}

.Button {
  height: calc(202px * var(--hRatio));
  width: calc(1116px * var(--wRatio));
  font-family: GothamRounded;
  font-size: calc(63px * var(--hRatio));
  color: #fff;
  background-color: #32496d;
  font-weight: 400;
  border: 0;
  border-radius: calc(300px * var(--wRatio));
}
