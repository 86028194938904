.Background2 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #f6f1e5;
  height: 100%;
  width: 100%;
  max-width: 500px;
  background-image: url('../../../assets/new_onboarding/splash\ screen.webp');
  background-size: cover;
  background-position: center calc(-720px * var(--hRatio))
}

.Background3 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #f6f1e5;
  height: 100%;
  width: 100%;
  max-width: 500px;
  background-image: url('../../../assets/new_onboarding/Onboarding\ IC\ selection.webp');
  background-size: cover;
  background-position: center 25%;
}

.Background {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #f6f1e5;
  height: 100%;
  width: 100%;
  max-width: 500px;
}

.Cloud1 {
  position: absolute;
  left: 0;
  top: calc(62px * var(--hRatio));
}

.Cloud2 {
  position: absolute;
  right: 0;
  top: calc(165px * var(--hRatio));
}

.Cloud3 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(578px * var(--hRatio));
  width: 100%;
}