.App {
  display: flex;
  justify-content: center;
  height: 100%;
  height: 100dvh;
  width: 100%;
  width: 100dvw;
  overflow-x: scroll;
  scrollbar-width: none;
  margin: 0;
}

@font-face {
  font-family: "Lato";
  font-weight: 400;
  src: local("Lato"), url("../../fonts/Lato-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  font-weight: 700;
  src: local("Lato"), url("../../fonts/Lato-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Lato";
  font-weight: 500;
  src: local("Lato"), url("../../fonts/Lato-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "GothamRounded";
  font-weight: 350;
  src: local("GothamRounded"),
    url("../../fonts/GothamRounded-Medium.otf") format("opentype");
}

@font-face {
  font-family: "GothamRounded";
  font-weight: 400;
  src: local("GothamRounded"),
    url("../../fonts/GothamRounded-Bold.otf") format("opentype");
}
