.SignupOptionBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(180px * var(--hRatio));
  width: calc(1200px * var(--wRatio));
  background-color: #fff;
  border: 0;
  border-radius: calc(300px * var(--wRatio));
}

.SignupBtnTxt {
  display: inline;
  font-family: GothamRounded;
  font-size: calc(63px * var(--hRatio));
  line-height: calc(63px * var(--hRatio));
  font-weight: 350;
  margin: 0;
  margin-left: calc(63px * var(--wRatio));
}

.SignupBtnIcon {
  margin-left: calc(180px * var(--wRatio));
}

.Tree {
  width: calc(600px * var(--wRatio));
  margin-top: calc(300px * var(--hRatio));
}

.Title {
  font-family: GothamRounded;
  font-weight: 400;
  line-height:  calc(79px * var(--hRatio));
  font-size: calc(79px * var(--hRatio));
  margin: 0;
}

.SubTitle {
  font-family: Lato;
  font-weight: 400;
  line-height: calc(60px * var(--hRatio));
  font-size: calc(60px * var(--hRatio));
  margin: 0;
}

.FooterTxt {
  font-family: Lato;
  font-weight: 400;
  line-height: calc(70px * var(--hRatio));
  font-size: calc(60px * var(--hRatio));
  margin: 0;
}