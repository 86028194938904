.BackBtn {
  display: flex;
  position: absolute;
  left: calc(63px * var(--wRatio));
  bottom: calc(157px * var(--hRatio));
  justify-content: center;
  align-items: center;
  width: 20%;
  height: calc(159px * var(--hRatio));
  border: 0;
  border-radius: calc(75px * var(--wRatio));
  background-color: rgba(0, 0, 0, 0.21);
  color: white;
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(54px * var(--hRatio));
}

.NextBtn {
  display: flex;
  position: absolute;
  right: calc(63px * var(--wRatio));
  bottom: calc(157px * var(--hRatio));
  justify-content: center;
  align-items: center;
  width: 65%;
  height: calc(159px * var(--hRatio));
  border: 0;
  border-radius: calc(75px * var(--hRatio));
  background-color: #31496f;
  color: white;
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(54px * var(--hRatio));
  margin-left: calc(39px * var(--wRatio));
}

.Question {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(90px * var(--hRatio));
  margin-left: calc(63px * var(--wRatio));
  margin-right: calc(63px * var(--wRatio));
  margin-top: calc(860px * var(--hRatio));
}

.Subtitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(60px * var(--hRatio));
  margin-left: calc(63px * var(--wRatio));
  margin-top: calc(-80px* var(--hRatio));
}

.MCQText {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(54px * var(--hRatio));
  color: rgb(0, 0, 0);
  text-align: start;
  margin: 0;
  margin-right: calc(30px * var(--wRatio));
}

.EmailPrompt {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(73px * var(--hRatio));
  text-align: start;
  margin: 0 calc(63px * var(--wRatio));
}

.EmailSubPrompt {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(49px * var(--hRatio));
  opacity: 0.55;
  text-align: start;
  margin: calc(12px * var(--hRatio)) calc(63px * var(--wRatio)) 0
    calc(63px * var(--wRatio));
}
