.Container {
  display: block;
  position: relative;
  background-color: #f6f1e5;
  width: 100%;
  max-width: 500px;
  padding-bottom: calc(358px * var(--hRatio));
}

.EmbraceLogo {
  height: calc(238px * var(--hRatio));
  position: absolute;
  left: calc(52px * var(--wRatio));
  top: calc(52px * var(--hRatio));
}
