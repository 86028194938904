.ScoreTitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(59px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(169px * var(--hRatio));
  text-align: center;
}

.ScoreSubTitle {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(92px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(15px * var(--hRatio));
  text-align: center;
}
.ScoreCardTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(73px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.ScoreCardDescription {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(58px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(39px * var(--hRatio));
  text-align: left;
}
