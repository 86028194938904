.MetricsContainer {
  position: relative;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f6f1e5;
}

.EvolveIcon {
  padding-top: calc(136px * var(--hRatio));
  align-items: center;
}

.MetricsTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  color: #31496e;
  margin-top: calc(128px * var(--hRatio));
  padding-left: calc(63px * var(--wRatio));
  padding-right: calc(63px * var(--wRatio));
}

.Graph {
  margin-top: calc(53px * var(--hRatio));
  height: calc(1066px * var(--hRatio));
  width: calc(1116px * var(--wRatio));
}

.Testimonials {
  display: flex;
  gap: calc(49px * var(--wRatio));
  flex-direction: row;
  overflow-x: scroll;
  padding: 0 calc(63px * var(--wRatio));
  scrollbar-width: none;
}

.TestimonialCard {
  position: relative;
  height: calc(578px * var(--hRatio));
  min-width: calc(1033px * var(--wRatio));
  border-radius: calc(63px * var(--wRatio));
  background-color: #fff;
}

.Ratings {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: calc(54px * var(--wRatio));
}

.RatingIcon {
  height: calc(59px * var(--hRatio));
  margin-top: calc(63px * var(--hRatio));
}

.Testimonial {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(49px * var(--hRatio));
  color: #31496e;
  margin-left: calc(54px * var(--wRatio));
  margin-right: calc(54px * var(--wRatio));
  text-align: left;
}

.UserName {
  position: absolute;
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(39px * var(--hRatio));
  color: #31496e;
  left: calc(54px * var(--hRatio));
  bottom: calc(39px * var(--hRatio));
}
