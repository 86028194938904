.SignUpTitle {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(82px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.SignUpSubtitle {
  font-family: "Lato";
  font-size: calc(64px * var(--hRatio));
  font-weight: 400;
  margin-top: calc(21px * var(--hRatio));
  color: #31496e;
}
