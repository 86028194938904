.ReviewsContainer {
  display: block;
  padding: calc(135px * var(--hRatio)) 0 calc(140px * var(--hRatio)) 0;
  background-color: #feedba;
  margin-top: calc(154px * var(--hRatio));
}

.ReviewsTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(85px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.ReviewsSubtitle {
  font-family: Lato;
  font-weight: 500;
  font-size: calc(48px * var(--hRatio));
  margin: 0;
  margin-top: calc(30px * var(--hRatio));
  color: #31496e;
}

.UserReviews {
  display: flex;
  flex-direction: row;
  gap: calc(49px * var(--wRatio));
  padding: 0 calc(63px * var(--wRatio));
  overflow-x: scroll;
  margin-top: calc(102px * var(--hRatio));
  scrollbar-width: none;
}

.Container1 {
  display: flex;
  justify-content: space-between;
  background-color: #feedba;
  padding: calc(85px * var(--hRatio)) calc(63px * var(--wRatio));
  margin-top: calc(154px * var(--hRatio));
}

.ContentHoursCard {
  flex: 1;
  background-color: white;
  border-radius: calc(39px * var(--wRatio));
  padding: calc(92px * var(--hRatio)) calc(21px * var(--wRatio));
}

.AvgRatingCard {
  flex: 1;
  background-color: white;
  border-radius: calc(39px * var(--wRatio));
  padding: calc(73px * var(--hRatio)) calc(39px * var(--wRatio));
  margin-left: calc(39px * var(--wRatio));
}

.ContentHours {
  font-family: GothamRounded;
  font-size: calc(102px * var(--hRatio));
  font-weight: 350;
  color: #31496e;
  margin: 0;
}

.AvgRating {
  font-family: GothamRounded;
  font-size: calc(85px * var(--hRatio));
  font-weight: 350;
  color: #31496e;
  margin: 0;
}

.CardSubTitle {
  font-family: Lato;
  font-size: calc(48px * var(--hRatio));
  font-weight: 400;
  color: #31496e;
  margin: 0;
}
