.WelcomeScreenBG {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}

.TncText {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(26px * var(--hRatio));
  color: rgba(0, 0, 0, 0.55);
  margin: 0;
  margin-top: calc(10px * var(--hRatio));
}

.CompanyName {
  margin-top: calc(4px * var(--hRatio));
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(24px * var(--hRatio));
  color: rgba(0, 0, 0, 0.39);
  margin: 0;
  margin-top: calc(10px * var(--hRatio));
}

.FooterContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(54px * var(--hRatio));
  align-items: center;
  z-index: 2;
}

.AdhdTitle1 {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  color: #31496E;
  margin: 0;
  margin-top: calc(910px * var(--hRatio));
}

.AdhdTitle2 {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(85px * var(--hRatio)); 
  color: #31496e;
  margin: 0;
  margin-top: calc(21px*var(--hRatio));
}

.Divider {
  height: calc(8px * var(--hRatio));
  width: calc(132px * var(--wRatio));
  background-color: #d17938;
  border-radius: calc(100px * var(--wRatio));
  margin: calc(85px * var(--hRatio)) auto;
}

.AdhdTitle3 {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(75px*var(--hRatio));
}
