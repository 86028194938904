.PrepareScoreTitle {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(80px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(497px * var(--hRatio));
  text-align: center;
}

.PrepareScoreSubTitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(64px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(15px * var(--hRatio));
  text-align: center;
}
