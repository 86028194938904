.WhyChooseUsContainer {
  padding: 0 calc(52px * var(--wRatio));
}

.Title {
  font-family: GothamRounded;
  font-size: calc(63px * var(--wRatio));
  font-weight: 400;
  color: #31496e;
  text-align: left;
  margin: 0;
  margin-top: calc(154px * var(--hRatio));
}

.CostTableContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: calc(39px * var(--hRatio));
}

.Column1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
}

.Column2 {
  display: flex;
  flex: 3;
  flex-direction: column;
}

.Column3 {
  display: flex;
  flex: 3;
  flex-direction: column;
}

.TableHeading {
  font-family: GothamRounded;
  font-size: calc(49px * var(--wRatio));
  font-weight: 350;
  color: #31496e;
  text-align: left;
  vertical-align: middle;
  margin: 0;
  padding: calc(43px * var(--hRatio)) calc(29px * var(--wRatio))
    calc(49px * var(--hRatio)) calc(44px * var(--hRatio));
}

.TableContent {
  font-family: Lato;
  font-size: calc(49px * var(--wRatio));
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
  margin: 0;
  padding: calc(60px * var(--hRatio)) calc(226px * var(--hRatio))
    calc(59px * var(--hRatio)) calc(44px * var(--wRatio));
  background-color: white;
}

.Card {
  background-color: white;
  border-radius: calc(39px * var(--wRatio));
  padding: calc(52px * var(--hRatio)) calc(52px * var(--wRatio));
  padding-bottom: calc(66px * var(--hRatio));
  margin-top: calc(63px * var(--hRatio));
}

.CardTitle {
  font-family: GothamRounded;
  font-size: calc(63px * var(--hRatio));
  font-weight: 400;
  color: #31496e;
  text-align: left;
  margin: 0;
}

.CardSubTitle {
  font-family: Lato;
  font-size: calc(39px * var(--hRatio));
  font-weight: 400;
  color: #31496e;
  text-align: left;
  margin: 0;
  margin-top: calc(13px * var(--hRatio));
}

.RecognitionCard {
  background-color: #fcedb5;
  padding: calc(45px * var(--hRatio)) calc(46px * var(--wRatio))
    calc(34px * var(--hRatio)) calc(32px * var(--wRatio));
  border-radius: calc(24px * var(--wRatio));
  margin-top: calc(54px * var(--hRatio));
}

.RecognitionDescription {
  font-family: Lato;
  font-weight: 700;
  font-size: calc(39px * var(--hRatio));
  color: #31496e;
  margin: 0;
  text-align: left;
}

.RecognitionAuthor {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(39px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(36px var(--hRatio));
  text-align: right;
}

.RecognitionDesignation {
  font-family: Lato;
  font-weight: 500;
  font-size: calc(39px * var(--hRatio));
  color: #31496e;
  margin: 0;
  text-align: right;
}
