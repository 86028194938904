.PremiumCard {
  height: calc(315px * var(--hRatio));
  width: 100%;
  border-radius: calc(63px * var(--wRatio));
  padding: calc(63px * var(--hRatio)) calc(63px * var(--wRatio));
  box-sizing: border-box;
}

.PremiumPlansTitle {
    font-family: GothamRounded;
    font-weight: 350;
    font-size: calc(95px * var(--hRatio));
    color: #31496e;
    margin: 0;
  }
  
  .PremiumDuration {
    font-family: GothamRounded;
    font-weight: 350;
    font-size: calc(73px * var(--hRatio));
    margin: 0 0 0 calc(21px * var(--wRatio));
  }
  
  .PremiumPrice {
    font-family: GothamRounded;
    font-size: calc(112px * var(--hRatio));
    font-weight: 350;
    margin: 0;
  }
  
  .PremiumCutPrice {
    font-family: GothamRounded;
    font-weight: 350;
    font-size: calc(49px * var(--hRatio));
    text-decoration: line-through;
    margin: 0;
    color: rgba(21, 21, 21, 0.55);
    text-align: end;
    opacity: 0.3;
  }
  
  .PerDayPrice {
    font-family: GothamRounded;
    font-size: calc(49px * var(--hRatio));
    font-weight: 350;
    margin: calc(21px * var(--hRatio)) 0 0 0;
    color: rgba(21, 21, 21, 0.55);
  }

  .PerDayPriceSymbol {
    font-family: Lato;
    font-size: calc(49px * var(--hRatio));
    font-weight: 400;
    margin-right: calc(8px * var(--wRatio));
    opacity: 0.55;
  }

  .Save50Text {
    font-family: GothamRounded;
    font-size: calc(49px * var(--hRatio));
    font-weight: 350;
    color: #31496e;
  }

  .SubscribeBtnTxt {
    font-family: GothamRounded;
    font-weight: 350;
    font-size: calc(73px * var(--hRatio));
    color: #fff;
    margin-left: calc(39px * var(--wRatio));
  }
  