.Title {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(73px * var(--hRatio));
  text-align: left;
  color: #31496e;
  margin: 0 calc(63px * var(--wRatio));
}

.SubTitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(58px * var(--hRatio));
  text-align: left;
  color: #31496e;
  margin: 0 calc(63px * var(--wRatio));
  margin-top: calc(39px * var(--hRatio));
}

.PriceTxt {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(82px * var(--hRatio));
  margin: 0;
}

.Currency {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(64px * var(--hRatio));
  color: rgba(0, 0, 0, 0.55);
  margin: 0;
}

.OneTimeTxt {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(38px * var(--hRatio));
  color: "rgba(0, 0, 0, 0.39)";
  margin: 0;
}

.RecommendedTxt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(80px * var(--hRatio));
  width: calc(280px * var(--wRatio));
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(31px * var(--hRatio));
  color: white;
  background-color: #31496e;
  border-radius: calc(63px * var(--wRatio));
  margin: 0;
}

.PlanIncludesTxt {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(49px * var(--hRatio));
  color: #36486b;
  margin: 0;
  margin-top: calc(49px * var(--hRatio));
  text-align: left;
}

.BenifitsTxt {
  font-family: Lato;
  font-weight: 500;
  font-size: calc(64px * var(--hRatio));
  margin: 0;
  text-align: left;
  margin-left: calc(24px * var(--wRatio));
}
