.WelcomeContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AdhdTitle1 {
    font-family: GothamRounded;
    font-weight: 400;
    font-size: calc(85px * var(--hRatio));
    color: #d17938;
    margin: 0;
    margin-top: calc(910px * var(--hRatio));
}

.AdhdTitle1_ {
  font-family: GothamRounded;
  font-weight: 4000;
  font-size: calc(85px * var(--hRatio));
  color: #31496E;
  margin: 0;
  margin-top: calc(910px * var(--hRatio));
}

.AdhdTitle2 {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.Divider {
    height: calc(8px * var(--hRatio));
    width: calc(132px * var(--wRatio));
    background-color: #d17938;
    border-radius: calc(100px * var(--wRatio));
    margin: calc(63px * var(--hRatio));
}

.AdhdTitle3 {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.WelcomeScreenBG {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}

.new_background {
  height: "100%";
  width: "100%";
  position: "absolute";
  z-index: -1;
}