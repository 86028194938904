.Bar {
  height: calc(12px * var(--hRatio));
  width: calc(468px * var(--wRatio));
  border-radius: calc(39px * var(--wRatio));
  background-color: rgba(0, 0, 0, 0.21);
}

.FillBar {
  transition: width 0.5s ease-in-out;
  height: calc(12px * var(--hRatio));
  border-radius: calc(39px * var(--wRatio));
  background-color: #31496e;
}
