.Button {
  font-family: GothamRounded;
  font-size: calc(58px * var(--hRatio));
  width: 100%;
  color: #fff;
  background-color: #32496d;
  font-weight: 400;
  border: 0;
  border-radius: calc(300px * var(--wRatio));
  padding: calc(72px * var(--hRatio)) calc(130px * var(--wRatio));
}
