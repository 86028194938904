.LoadingContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}

.LoadingTitle {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(72px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin: calc(168px * var(--hRatio)) auto 0 auto;
}

.LoadingSubtitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(54px * var(--hRatio));
  color: #31496e;
  margin: calc(15px * var(--hRatio)) auto 0 auto;
}

.EvolveIcon {
  padding-top: calc(136px * var(--hRatio));
  align-items: center;
}