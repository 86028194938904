.Title {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(82px * var(--hRatio));
  color: #31496e;
  text-align: center;
  margin: 0;
  margin-top: calc(266px * var(--hRatio));
}

.SubTitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(64px * var(--hRatio));
  color: #31496e;
  text-align: center;
  margin: 0 calc(63px * var(--wRatio));
  margin-top: calc(21px * var(--hRatio));
}
