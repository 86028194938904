.PremiumPlansTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(85px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.PremiumPlansContainer {
  padding: calc(135px * var(--hRatio)) calc(63px * var(--wRatio))
    calc(204px * var(--hRatio)) calc(63px * var(--wRatio));
  background-color: #f6f1e5;
}

.PremiumPlans {
  margin-top: calc(102px * var(--hRatio));
}

.FlexRow {
  display: flex;
  flex-direction: row;
}

.FlexSpaceBetween {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.PremiumCard {
  height: calc(295px * var(--hRatio));
  width: 100%;
  border-radius: calc(63px * var(--wRatio));
  padding: calc(63px * var(--hRatio)) calc(63px * var(--wRatio));
  box-sizing: border-box;
}

.PremiumDuration {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  margin: 0 0 0 calc(21px * var(--wRatio));
}

.PremiumPrice {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(102px * var(--hRatio));
  margin: 0;
}

.PremiumCutPrice {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(39px * var(--hRatio));
  text-decoration: line-through;
  margin: 0;
  color: rgba(21, 21, 21, 0.55);
  text-align: end;
  opacity: 0.3;
}

.PerDayPrice {
  font-family: GothamRounded;
  font-size: calc(39px * var(--hRatio));
  font-weight: 350;
  margin: calc(21px * var(--hRatio)) 0 0 0;
  color: rgba(21, 21, 21, 0.55);
}

.PerDayPriceSymbol {
  font-family: Lato;
  font-size: calc(39px * var(--hRatio));
  font-weight: 400;
  margin-right: calc(8px * var(--wRatio));
  opacity: 0.55;
}

.Save50 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(95px * var(--hRatio));
  width: calc(277px * var(--wRatio));
  border-radius: calc(100px * var(--wRatio));
  background-color: #fff;
  margin-bottom: calc(49px * var(--hRatio));
}

.Save50Text {
  font-family: GothamRounded;
  font-size: calc(39px * var(--hRatio));
  font-weight: 350;
  color: #31496e;
}

.SubscribeBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: calc(202px * var(--hRatio));
  width: calc(1116px * var(--wRatio));
  background-color: #31496e;
  border-radius: calc(102px * var(--wRatio));
  margin-top: calc(102px * var(--hRatio));
  margin-left: auto;
  margin-right: auto;
}

.SubscribeBtnTxt {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  color: #fff;
  margin-left: calc(39px * var(--wRatio));
}
