.SignUpBG {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}

.EvolveIcon {
  padding-top: calc(136px * var(--hRatio));
  align-items: center;
}

.SignupOptionBtnContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
}

.SignupOptionBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: calc(160px * var(--hRatio));
  width: calc(920px * var(--wRatio));
  background-color: #fff;
  border: 0;
  border-radius: calc(300px * var(--wRatio));
}

.SignupBtnTxt {
  display: inline;
  font-family: GothamRounded;
  font-size: calc(49px * var(--hRatio));
  line-height: calc(49px * var(--hRatio));
  font-weight: 350;
  margin: 0;
  margin-left: calc(63px * var(--wRatio));
}

.SignupBtnIcon {
  margin-left: calc(148px * var(--wRatio));
}

.Tree {
  width: calc(550px * var(--wRatio));
  margin-top: calc(300px * var(--hRatio));
}

.Title {
  font-family: GothamRounded;
  font-weight: 400;
  line-height:  calc(63px * var(--hRatio));
  font-size: calc(63px * var(--hRatio));
  margin: 0;
}

.SubTitle {
  font-family: Lato;
  font-weight: 400;
  line-height:  calc(48px * var(--hRatio));
  font-size: calc(48px * var(--hRatio));
  margin: 0;
}

.FooterTxt {
  font-family: Lato;
  font-weight: 400;
  line-height:  calc(54px * var(--hRatio));
  font-size: calc(48px * var(--hRatio));
  margin: 0;
}