.PremiumScreenContainer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: calc(102px * var(--hRatio));
  background-color: #f6f1e5;
}

.PremiumCoverImage {
  height: calc(726px * var(--hRatio));
  width: 100%;
}

.PremiumBenefits {
  display: block;
  height: calc(4153px * var(--hRatio));
  width: 100%;
}

.GooglePlayAward {
  position: absolute;
  height: calc(265px * var(--hRatio));
  width: calc(587px * var(--wRatio));
  top: calc(230px * var(--hRatio));
  transform: translate(50%, 0);
  right: 50%;
  z-index: 2;
}

.ReviewsContainer {
  display: block;
  padding: calc(135px * var(--hRatio)) 0 calc(140px * var(--hRatio)) 0;
  background-color: #feedba;
}

.ReviewsTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(85px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.ReviewsSubtitle {
  font-family: Lato;
  font-weight: 500;
  font-size: calc(48px * var(--hRatio));
  margin: 0;
  margin-top: calc(30px * var(--hRatio));
  color: #31496e;
}

.UserReviews {
  display: flex;
  flex-direction: row;
  gap: calc(49px * var(--wRatio));
  padding: 0 calc(63px * var(--wRatio));
  overflow-x: scroll;
  margin-top: calc(102px * var(--hRatio));
  scrollbar-width: none;
}
