.ScorePreparationContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  background-color: #f6f1e5;
  height: 100%;
  width: 100%;
  max-width: 500px;
  padding: 0 calc(77px * var(--wRatio)) 0 calc(77px * var(--wRatio));
}

.PrepareScoreTitle {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(70px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(497px * var(--hRatio));
  text-align: center;
}

.PrepareScoreSubTitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(54px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(15px * var(--hRatio));
  text-align: center;
}

.Tree {
  width: calc(467px * var(--wRatio));
  margin-top: calc(337px * var(--hRatio));
}

.Loader {
  margin-top: calc(200px * var(--hRatio));
}

.Cloud3 {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(578px * var(--hRatio));
  width: 100%;
}
