.InputContainer {
  display: flex;
  position: relative;
  padding: 0 calc(63px * var(--wRatio)) 0 calc(63px * var(--wRatio));
  align-items: center;
}

.TextInput {
  position: relative;
  height: calc(172px * var(--hRatio));
  width: 100%;
  font-family: "Lato";
  font-size: calc(54px * var(--hRatio));
  font-weight: 400;
  border-radius: calc(39px * var(--wRatio));
  box-sizing: border-box;
  border: calc(4px * var(--hRatio)) solid rgba(121, 121, 121, 0.55);
  background-color: #f5f0e3;
  padding-left: calc(63px * var(--wRatio));
}

.InputTitleContainer {
  position: absolute;
  height: calc(47px * var(--hRatio));
  left: calc(122px * var(--wRatio));
  top: calc(-23px * var(--hRatio));
  font-weight: 400;
  color: rgba(0, 0, 0, 0.55);
  background-color: #f5f0e3;
  z-index: 2;
}

.InputTitle {
  margin: 0;
  font-family: "Lato";
  font-size: calc(39px * var(--hRatio));
  font-weight: 400;
  color: rgba(0, 0, 0, 0.55);
  text-align: left;
  padding-left: calc(32px * var(--wRatio));
  padding-right: calc(32px * var(--wRatio));
}

.InputError {
  font-family: "Lato";
  font-size: calc(39px * var(--hRatio));
  font-weight: 400;
  margin: calc(8px * var(--hRatio)) 0 0 0;
  text-align: left;
  margin-left: calc(87px * var(--wRatio));
  color: red;
}

.ShowPassword {
  position: absolute;
  right: calc(111px * var(--wRatio));
}

input:focus {
  outline: none;
}
