.ScoreContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f6f1e5;
  height: 100%;
  width: 100%;
  max-width: 500px;
  padding: 0 calc(77px * var(--wRatio)) 0 calc(77px * var(--wRatio));
}

.ScoreTitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(49px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(377px * var(--hRatio));
  text-align: center;
}

.ScoreSubTitle {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(82px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(15px * var(--hRatio));
  text-align: center;
}

.ScoreCard {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: calc(63px * var(--wRatio)) calc(63px * var(--hRatio));
  border-radius: calc(39px * var(--wRatio));
  align-items: flex-start;
}

.ScoreCardTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.ScoreCardDescription {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(48px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(39px * var(--hRatio));
  text-align: left;
}

.ScoreIcon {
  height: calc(466px * var(--hRatio));
  margin-top: calc(90px * var(--hRatio));
}
