.Container {
  position: relative;
  margin-top: calc(504px * var(--hRatio));
  padding: 0 calc(63px * var(--wRatio));
}

.CardContainer {
  position: relative;
  padding: calc(63px * var(--hRatio)) calc(50px * var(--wRatio));
  background-color: white;
  border-radius: calc(63px * var(--wRatio));
}

.Title {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(63px * var(--hRatio));
  color: #31496e;
  text-align: left;
  margin: 0;
}

.Description {
  font-family: Lato;
  font-weight: 400;
  position: relative;
  font-size: calc(48px * var(--hRatio));
  color: #31496e;
  text-align: left;
  margin: 0;
  margin-top: calc(39px * var(--hRatio));
}

.InnerCard {
  display: flex;
  flex-direction: row;
  background-color: #bcdbf6;
  border-radius: calc(39px * var(--wRatio));
  padding: calc(52px * var(--hRatio)) calc(52px * var(--wRatio));
  padding-left: calc(70px * var(--wRatio));
  margin-top: calc(69px * var(--hRatio));
}

.Description2 {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(39px * var(--hRatio));
  color: #31496e;
  text-align: left;
  margin: 0;
  margin-top: calc(39px * var(--hRatio));
  margin-right: calc(100px * var(--wRatio));
}

.ADHDPointerCard {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: calc(39px * var(--hRatio)) calc(39px * var(--wRatio));
  border-radius: calc(39px * var(--wRatio));
  margin-top: calc(49px * var(--hRatio));
}

.ADHDPointerCardIcon {
  height: calc(90px * var(--hRatio));
}

.ADHDPointerCardTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(54px * var(--hRatio));
  color: #31496e;
  text-align: left;
  margin: 0;
}
