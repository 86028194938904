.TestimonialCard {
  position: relative;
  height: calc(578px * var(--hRatio));
  min-width: calc(1033px * var(--wRatio));
  border-radius: calc(63px * var(--wRatio));
  background-color: #fff;
}

.Ratings {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: calc(54px * var(--wRatio));
}

.RatingIcon {
  height: calc(59px * var(--hRatio));
  margin-top: calc(63px * var(--hRatio));
}

.Testimonial {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(49px * var(--hRatio));
  color: #31496e;
  margin-left: calc(54px * var(--wRatio));
  margin-right: calc(54px * var(--wRatio));
  text-align: left;
}

.UserName {
  position: absolute;
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(39px * var(--hRatio));
  color: #31496e;
  left: calc(54px * var(--hRatio));
  bottom: calc(63px * var(--hRatio));
}
