.FreeTrialTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(73px * var(--hRatio));
  color: #31496e;
  text-align: start;
  margin: 0;
}

.FreeTrialText {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(58px * var(--hRatio));
  color: #31496e;
  text-align: start;
  margin: 0;
  margin-top: calc(54px * var(--hRatio));
}

.BenefitTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(112px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.BenefitText {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(58px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.ContentTitle {
  font-family: GothamRounded;
  font-size: calc(95px * var(--hRatio));
  font-weight: 350;
  color: #31496e;
  text-align: center;
  margin: 0;
  margin-top: calc(204px * var(--hRatio));
}
