.DownloadAppBG {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}

.EvolveIcon {
  padding-top: calc(136px * var(--hRatio));
  align-items: center;
}

.Title {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(72px * var(--hRatio));
  color: #31496e;
  text-align: center;
  margin: 0;
  margin-top: calc(266px * var(--hRatio));
}

.SubTitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(54px * var(--hRatio));
  color: #31496e;
  text-align: center;
  margin: 0 calc(63px * var(--wRatio));
  margin-top: calc(21px * var(--hRatio));
}
