.LoadingTitle {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(82px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin: calc(168px * var(--hRatio)) auto 0 auto;
}

.LoadingSubtitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(64px * var(--hRatio));
  color: #31496e;
  margin: calc(15px * var(--hRatio)) auto 0 auto;
}
