.Title {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(63px * var(--hRatio));
  text-align: left;
  color: #31496e;
  margin: 0 calc(63px * var(--wRatio));
}

.SubTitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(48px * var(--hRatio));
  text-align: left;
  color: #31496e;
  margin: 0 calc(63px * var(--wRatio));
  margin-top: calc(39px * var(--hRatio));
}

.PlansContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: scroll;
  margin-top: calc(54px * var(--hRatio));
  scrollbar-width: none;
}

.PlanCard {
  position: relative;
  min-width: calc(694px * var(--wRatio));
  padding: calc(63px * var(--hRatio)) calc(49px * var(--wRatio));
  border-radius: calc(39px * var(--wRatio));
  background-color: white;
}

.JustifyFlex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.PriceTxt {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(72px * var(--hRatio));
  margin: 0;
}

.Currency {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(54px * var(--hRatio));
  color: rgba(0, 0, 0, 0.55);
  margin: 0;
}

.OneTimeTxt {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(28px * var(--hRatio));
  color: "rgba(0, 0, 0, 0.39)";
  margin: 0;
}

.RecommendedTxt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(70px * var(--hRatio));
  width: calc(216px * var(--wRatio));
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(21px * var(--hRatio));
  color: white;
  background-color: #31496e;
  border-radius: calc(63px * var(--wRatio));
  margin: 0;
}

.Separator {
  width: 100%;
  height: 0.5px;
  background-color: rgba(0, 0, 0, 0.21);
  margin-top: calc(39px * var(--hRatio));
  margin-bottom: calc(49px * var(--hRatio));
}

.PlanIncludesTxt {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(39px * var(--hRatio));
  color: #36486b;
  margin: 0;
  margin-top: calc(49px * var(--hRatio));
  text-align: left;
}

.BenifitsTxt {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(54px * var(--hRatio));
  margin: 0;
  text-align: left;
  margin-left: calc(24px * var(--wRatio));
}
