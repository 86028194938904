.AdhdTitle1 {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(95px * var(--hRatio));
  color: #d17938;
  margin: 0;
  margin-top: calc(910px * var(--hRatio));
}

.AdhdTitle1_ {
  font-family: GothamRounded;
  font-weight: 4000;
  font-size: calc(85px * var(--hRatio));
  color: #31496E;
  margin: 0;
  margin-top: calc(910px * var(--hRatio));
}

.AdhdTitle2 {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.AdhdTitle3 {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  color: #31496e;
  margin: 0;
}
