.Title {
  font-family: GothamRounded;
  font-size: calc(63px * var(--wRatio));
  font-weight: 400;
  color: #31496e;
  text-align: left;
  margin: 0;
  margin-top: calc(154px * var(--hRatio));
}

.TableHeading {
  font-family: GothamRounded;
  font-size: calc(59px * var(--wRatio));
  font-weight: 350;
  color: #31496e;
  text-align: left;
  vertical-align: middle;
  margin: 0;
  padding: calc(43px * var(--hRatio)) calc(29px * var(--wRatio))
    calc(49px * var(--hRatio)) calc(44px * var(--hRatio));
}

.TableContent {
  font-family: Lato;
  font-size: calc(59px * var(--wRatio));
  font-weight: 500;
  text-align: left;
  vertical-align: middle;
  margin: 0;
  padding: calc(60px * var(--hRatio)) calc(226px * var(--hRatio))
    calc(59px * var(--hRatio)) calc(44px * var(--wRatio));
  background-color: white;
}

.CardTitle {
  font-family: GothamRounded;
  font-size: calc(73px * var(--hRatio));
  font-weight: 400;
  color: #31496e;
  text-align: left;
  margin: 0;
}

.CardSubTitle {
  font-family: Lato;
  font-size: calc(49px * var(--hRatio));
  font-weight: 400;
  color: #31496e;
  text-align: left;
  margin: 0;
  margin-top: calc(13px * var(--hRatio));
}

.RecognitionDescription {
  font-family: Lato;
  font-weight: 700;
  font-size: calc(49px * var(--hRatio));
  color: #31496e;
  margin: 0;
  text-align: left;
}

.RecognitionAuthor {
  font-family: GothamRounded;
  font-weight: 400;
  font-size: calc(49px * var(--hRatio));
  color: #31496e;
  margin: 0;
  margin-top: calc(36px var(--hRatio));
  text-align: right;
}

.RecognitionDesignation {
  font-family: Lato;
  font-weight: 500;
  font-size: calc(49px * var(--hRatio));
  color: #31496e;
  margin: 0;
  text-align: right;
}
