.ButtonContainer {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: calc(118px * var(--hRatio));
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.Button {
  font-family: GothamRounded;
  font-size: calc(48px * var(--hRatio));
  width: 100%;
  color: #fff;
  background-color: #32496d;
  font-weight: 400;
  border: 0;
  border-radius: calc(300px * var(--wRatio));
  padding: calc(49px * var(--hRatio)) calc(130px * var(--wRatio))
    calc(49px * var(--hRatio)) calc(130px * var(--wRatio));
}
