.Container {
  width: 100%;
  background-color: #f6f1e5;
  max-width: 500px;
}

.FreeTrialCard {
  margin: 0 calc(70px * var(--wRatio));
  background-color: white;
  border-radius: calc(63px * var(--wRatio));
  padding: calc(76px * var(--hRatio)) calc(50px * var(--wRatio))
    calc(124px * var(--hRatio)) calc(50px * var(--wRatio));
}

.FreeTrialTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  color: #31496e;
  text-align: start;
  margin: 0;
}

.FreeTrialText {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(48px * var(--hRatio));
  color: #31496e;
  text-align: start;
  margin: 0;
  margin-top: calc(54px * var(--hRatio));
}

.BenefitsSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #feedba;
  padding: calc(85px * var(--hRatio)) calc(63px * var(--wRatio));
  gap: calc(39px * var(--wRatio));
  margin-top: calc(204px * var(--hRatio));
}

.BenefitCard {
  flex: 1;
  background-color: white;
  border-radius: calc(39px * var(--wRatio));
  padding: calc(63px * var(--hRatio)) calc(21px * var(--wRatio));
  min-width: calc(538px * var(--wRatio));
}

.BenefitTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(102px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.BenefitText {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(48px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.ContentTitle {
  font-family: GothamRounded;
  font-size: calc(85px * var(--hRatio));
  font-weight: 350;
  color: #31496e;
  text-align: center;
  margin: 0;
  margin-top: calc(204px * var(--hRatio));
}

.ContentTags {
  width: 100%;
  margin-top: calc(117px * var(--hRatio));
}

.ImagesContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: calc(265px * var(--hRatio));
  padding-bottom: calc(215px * var(--hRatio));
}

.InclusiveAppImg {
  width: calc(285px * var(--wRatio));
  margin-right: calc(37px * var(--wRatio));
}

.BestAppAwardImg {
  width: calc(393px * var(--wRatio));
}

.AppleEditorImg {
  width: calc(400px * var(--wRatio));
}
