.Container {
  flex: 1;
  position: relative;
  text-align: center;
}

.Background {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  max-width: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
}

.Header {
  margin-top: calc(218px * var(--hRatio));
}

.CrossIcon {
  position: absolute;
  right: calc(63px * var(--wRatio));
  top: calc(218px * var(--hRatio));
}

.BarContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: calc(275px * var(--hRatio));
}

.BackBtn {
  display: flex;
  position: absolute;
  left: calc(63px * var(--wRatio));
  bottom: calc(157px * var(--hRatio));
  justify-content: center;
  align-items: center;
  width: 20%;
  height: calc(159px * var(--hRatio));
  border: 0;
  border-radius: calc(75px * var(--wRatio));
  background-color: rgba(0, 0, 0, 0.21);
  color: white;
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(54px * var(--hRatio));
}

.NextBtn {
  display: flex;
  position: absolute;
  right: calc(63px * var(--wRatio));
  bottom: calc(157px * var(--hRatio));
  justify-content: center;
  align-items: center;
  width: 65%;
  height: calc(159px * var(--hRatio));
  border: 0;
  border-radius: calc(75px * var(--hRatio));
  background-color: #31496f;
  color: white;
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(54px * var(--hRatio));
  margin-left: calc(39px * var(--wRatio));
}

.Question {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  margin-left: calc(63px * var(--wRatio));
  margin-right: calc(63px * var(--wRatio));
  margin-top: calc(389px * var(--hRatio));
}

.Subtitle {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(39px * var(--hRatio));
}

.MCQOption {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: "100%";
  border: 0;
  border-radius: calc(54px * var(--wRatio));
  padding: calc(48px * var(--wRatio)) calc(63px * var(--wRatio))
    calc(48px * var(--wRatio)) calc(63px * var(--wRatio));
  margin-left: calc(63px * var(--wRatio));
  margin-right: calc(63px * var(--wRatio));
  margin-bottom: calc(37px * var(--hRatio));
}

.MCQText {
  font-family: Lato;
  font-weight: 400;
  font-size: calc(54px * var(--hRatio));
  color: white;
  text-align: start;
  margin: 0;
  margin-right: calc(30px * var(--wRatio));
}

.EmailPrompt {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(63px * var(--hRatio));
  text-align: start;
  margin: 0 calc(63px * var(--wRatio));
}

.EmailSubPrompt {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(39px * var(--hRatio));
  opacity: 0.55;
  text-align: start;
  margin: calc(12px * var(--hRatio)) calc(63px * var(--wRatio)) 0
    calc(63px * var(--wRatio));
}
