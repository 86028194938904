.ReviewsTitle {
  font-family: GothamRounded;
  font-weight: 350;
  font-size: calc(95px * var(--hRatio));
  color: #31496e;
  margin: 0;
}

.ReviewsSubtitle {
  font-family: Lato;
  font-weight: 500;
  font-size: calc(58px * var(--hRatio));
  margin: 0;
  margin-top: calc(30px * var(--hRatio));
  color: #31496e;
}

.ContentHours {
  font-family: GothamRounded;
  font-size: calc(112px * var(--hRatio));
  font-weight: 350;
  color: #31496e;
  margin: 0;
}

.AvgRating {
  font-family: GothamRounded;
  font-size: calc(95px * var(--hRatio));
  font-weight: 350;
  color: #31496e;
  margin: 0;
}

.CardSubTitle {
  font-family: Lato;
  font-size: calc(58px * var(--hRatio));
  font-weight: 400;
  color: #31496e;
  margin: 0;
}
